/*Normalize*/

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
p,
ul {
  margin: 0;
  padding: 0;
}

a,
a:hover,
a:active,
a:focus {
  text-decoration: none;
}

button,
input,
textarea,
select {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  border: none;
  outline: none;
  box-shadow: none;
  -webkit-appearance: none;
}

li {
  list-style-type: none;
}

:active,
:hover,
:focus {
  outline: 0;
  outline-offset: 0;
}


/*Fonts*/

@font-face {
  font-family: "Wicked Mouse";
  src: url("../fonts/WickedMouse.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}


/*Var*/

@main-color: #5F4CA8;
@light-color: #b39ef2;
@text-color: #ffffff;
@accent1-color: #ffd06a;
@accent2-color: #ff8db1;
@accent3-color: #00B8A2;
@shadow-color: #000000;

/*Dev*/

.hidden {
  display: none !important;
}

/*Common*/

html {
  font-size: 15px;
  scroll-behavior: smooth;
}

:target:before {
  content: "";
  display: block;
  height: 100px;
  margin: -100px 0 0;
}

body {
  font-family: 'Poppins', 'Arial', sans-serif;
  font-weight: 500;
  position: relative;
  margin: auto;
  background-color: @main-color;
  min-width: 320px;
  min-height: 100vh;
  font-size: 1.5rem;
  line-height: 150%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.noscroll {
  overflow: hidden;
}

.wrapper {
  width: 75rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
}

a:hover {
  transition: .2s;
}

.button {
  display: inline-block;
  font-size: 1rem;
  line-height: 100%;
  font-weight: 600;
  color: @shadow-color;
  text-align: center;
  text-transform: uppercase;
  background-color: @accent2-color;
  min-width: 15rem;
  padding: 1.4rem;
  cursor: pointer;
  border-radius: 1.5rem;
  border: 1px solid @shadow-color;
  box-shadow: 4px 4px 0px @shadow-color;
  margin-left: auto;
  margin-right: auto;
  &:hover {
    background-color: #e37e9e;
  }
  &_violet {
    margin-left: 1.5rem;
    background-color: #826CCB;
    &:hover {
      background-color: #7360b3;
    }
  }
  span {
    text-transform: none;
  }
}

.title {
  font-family: 'Wicked Mouse', 'Arial', sans-serif;
  font-size: 3rem;
  color: @text-color;
  letter-spacing: .3rem;
  line-height: 160%;
  text-shadow: 4px 4px 0px @shadow-color;
  span {
    color: @accent1-color;
  }
}

.subtitle {
  font-size: 3.5rem;
  font-weight: 800;
  color: @text-color;
  line-height: 135%;
  min-height: 5rem;
  display: flex;
  align-items: center;
  margin-bottom: 2.5rem;
  text-transform: uppercase;
}


/*Header*/

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  z-index: 10;
  transition: .3s;
  padding: 3.5rem 2rem;
  &.scrolled {
    background-color: @main-color;
    border-bottom: 4px solid #564497;
    padding-top: .8rem;
    padding-bottom: .8rem;
    .header__logo {
      height: 3.5rem;
    }
  }
  &__wrapper {
    flex-wrap: nowrap;
    width: 100%;
    max-width: 84rem;
    justify-content: space-between;
    align-items: center;
  }
  &__logo {
    height: 4.5rem;
    z-index: 3;
    transition: .3s;
    img {
      height: 100%;
    }
  }
  &__shadow {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.3);
  }
  &__menu {
    display: flex;
    align-items: center;
    &-item {
      font-size: 1rem;
      text-transform: uppercase;
      &:not(:first-of-type) {
        margin-left: 2.5rem;
      }
      a {
        color: @text-color;
        &:hover {
          opacity: .7;
        }
      }
    }
  }
}

.btn-menu {
  background-color: transparent;
  cursor: pointer;
  display: none;
  text-align: center;
  position: relative;
  z-index: 2;
  height: 1.5rem;
  flex-direction: column;
  justify-content: space-between;
  span {
    background-color: @text-color;
    display: block;
    width: 3rem;
    height: .4rem;
    border: 1px solid @shadow-color;
    box-shadow: 2px 2px 0px @shadow-color;
    position: relative;
    transition: .2s;
  }
  &.active {
    span {
      box-shadow: 0px 2px 0px @shadow-color;
      &:first-of-type {
        top: .5rem;
        transform: rotate(45deg);
      }
      &:last-of-type {
        bottom: .4rem;
        transform: rotate(-45deg);
      }
    }
  }
}


/*Banner*/

.banner {
  padding-top: 5rem;
  padding-bottom: 5rem;
  min-height: 80vh;
  background: url(../img/banner-bg.png) no-repeat left 40vw center/60% auto;
  overflow: hidden;
  position: relative;
  &__wrapper {
    position: relative;
  }
  &__deco {
    color: @text-color;

    div {
      position: absolute;
      &:nth-of-type(1) {
        left: 58%;
        top: 34%;
        b {
          font-size: 4rem;
          display: block;
          margin-bottom: 1rem;
        }
      }
      &:nth-of-type(2) {
        font-size: 1rem;
        text-align: center;
        right: 10%;
        top: 10%;
        b {
          font-size: 3rem;
          display: block;
        }
      }
      &:nth-of-type(3),
      &:nth-of-type(4),
      &:nth-of-type(5) {
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% auto;
      }
      &:nth-of-type(3) {
        background-image: url("../img/banner-deco1.png");
        width: 8.6rem;
        height: 9.3rem;
        top: 26%;
        right: 11%;
      }
      &:nth-of-type(4) {
        background-image: url("../img/banner-deco2.png");
        width: 6.6rem;
        height: 6.7rem;
        bottom: 28%;
        left: 52%;
      }
      &:nth-of-type(5) {
        background-image: url("../img/banner-deco3.png");
        width: 9.3rem;
        height: 9.3rem;
        right: 10%;
        bottom: 25%;
      }
    }
  }
  &__flag {
    width: 100%;
    position: absolute;
    top: 33vw;
    left: 50%;
    transform: translateX(-50%);
    &.mobile {
      display: none;
    }
  }
  &__about {
    width: 60%;
    position: relative;
    z-index: 2;
  }
  &__title {
    margin-bottom: 3rem;
    font-size: 1.9rem;
    strong {
      display: inline-block;
    }
    em {
      color: @light-color;
      font-style: initial;
    }
  }
  &__text {
    color: @text-color;
    width: 85%;
    margin-bottom: 3.5rem;
    span {
      color: @accent1-color;
    }
  }
  &__subtitle {
    font-size: 1.4rem;
    margin-bottom: 1.5rem;
    letter-spacing: .15rem;
    span {
      font-size: 1rem;
      color: @text-color;
    }
  }
  &__more {
    margin-top: 2rem;
    width: 80%;
    b {
      font-weight: 700;
    }
    span {
      font-weight: 700;
      color: @accent1-color;
    }
  }
  &__buttons {
    a {
      &:last-of-type {
        margin-left: 1.5rem;
        background-color: @light-color;
        &:hover {
          background-color: #7360b3;
        }
      }
    }
  }
}


/*Work*/

.work {
  padding-bottom: 6rem;
  &__wrapper {
    flex-direction: column;
    align-items: center;
    position: relative;
    &::before {
      content: '';
      width: 10.5rem;
      height: 7.8rem;
      background: url("../img/origami.svg") no-repeat center / contain;
      position: absolute;
      right: -2rem;
      bottom: 95%;
    }
  }
  &__subtitle {
    font-size: 2.2rem;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 150%;
    text-align: center;
    width: 85%;
    margin-bottom: 1.5rem;
    span {
      color: #98E756;
    }
  }
  &__about {
    width: 90%;
    text-align: center;
  }
  &__button {
    margin-top: 3rem;
    background-color: @accent1-color;
    text-transform: none;
    &:hover {
      background-color: #e3ba5f;
    }
  }
  &__more {
    width: 50%;
    text-align: center;
    margin-top: 1.5rem;
    b {
      font-weight: 700;
    }
    a {
      font-weight: 700;
      color: @accent2-color;
      text-decoration: underline;
    }
  }
}


/*About*/

.about, .fair {
  padding-top: 8rem;
  padding-bottom: 4.5rem;
  overflow: hidden;
  &__subtitle {
    padding-left: 7.5rem;
    background: url("../img/coin.png") no-repeat left center / 5rem auto;
  }
  &__text {
    width: 75%;
    margin-bottom: 2rem;
  }
  &__course {
    font-family: 'Wicked Mouse', 'Arial', sans-serif;
    font-size: 2rem;
    letter-spacing: .3rem;
    line-height: initial;
    text-shadow: 4px 4px 0px @shadow-color;
    margin-bottom: 2rem;
    b {
      font-family: 'Poppins', 'Arial', sans-serif;
      font-weight: 800;
    }
  }
  &__charge {
    margin-bottom: 3rem;
    span {
      font-weight: 700;
      color: @accent1-color;
    }
  }
  &__button {
    margin-left: 0;
  }
  &__card {
    background-color: @text-color;
    color: @shadow-color;
    border-radius: 4rem;
    padding: 3.5rem 4rem;
    border: 1px solid @shadow-color;
    box-shadow: 4px 4px 0px @shadow-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4rem;
    p {
      width: 55%;
    }
  }
  &__no {
    font-family: 'Wicked Mouse', 'Arial', sans-serif;
    font-size: 3rem;
    color: @accent1-color;
    text-shadow: 4px 4px 0px @shadow-color;
    line-height: 150%;
    margin-left: 2rem;
    span {
      display: block;
      white-space: nowrap;
      &:last-of-type {
        color: #FFA987;
      }
    }
  }
  &__note {
    width: 102vw;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: @accent1-color;
    color: @shadow-color;
    text-align: center;
    font-weight: 700;
    border: 1px solid @shadow-color;
    box-shadow: 4px 4px 0px @shadow-color;
    transform: rotate(1deg) translateX(-1vw);
    p {
      display: inline-block;
    }
    span {
      font-size: 2rem;
      font-style: italic;
    }
  }
}

.chart {
  width: 100%;
  border: 0;
  border-collapse: collapse;
  caption {
    text-align: left;
    color: @light-color;
    font-size: 3rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 4rem;
  }
  th {
    font-weight: 700;
    text-transform: uppercase;
    color: @accent1-color;
    padding-bottom: 1.5rem;
  }
  td {
    padding: 1.5rem;
    font-family: 'Wicked Mouse', 'Arial', sans-serif;
    font-size: 2rem;
    text-shadow: 3px 3px 0px @shadow-color;
    letter-spacing: .1rem;
    text-align: center;
    border:4px #564497 solid;
    border-collapse:collapse;
    &:last-of-type {
      color: @accent1-color;
      border-right: none;
    }
    &:first-of-type {
      border-left: none;
    }
    b {
      font-family: 'Poppins', 'Arial', sans-serif;
      font-weight: 800;
    }
    span {
      &:last-of-type {
        display: none;
      }
    }
  }
  tr {
    &:nth-of-type(2) {
      td {
        border-top: none;
      }
    }
    &:last-of-type {
      td {
        border-bottom: none;
        padding-bottom: 2.5rem;
      }
    }
  }
}


/*Aim*/

.aim {
  padding-bottom: 10rem;
  &__wrapper {
    align-items: flex-start;
    justify-content: space-between;
  }
  &__title {
    margin-bottom: 2rem;
  }
  &__text {
    width: 42%;
  }
  &__chart {
    width: 48%;
    position: relative;
    img {
      width: 100%;
    }
    span {
      width: 55%;
      font-weight: 700;
      text-transform: uppercase;
      color: @light-color;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  &__button {
    margin-top: 3rem;
    &.mobile {
      display: none;
    }
  }
}


/*Buy*/

.buy {
  padding-top: 2rem;
  padding-bottom: 10rem;
  &__wrapper {
    padding-left: 23%;
    background: url("../img/coin3.png") no-repeat left top / 23% auto;
  }
  &__subtitle {
    display: initial;
    span {
      font-weight: 300;
    }
  }
  &__text {
    a {
      color: @accent2-color;
      font-weight: 700;
      text-transform: uppercase;
      text-decoration: underline;
      &:hover {
        opacity: .7;
      }
    }
  }
  &__button {
    margin-top: 3rem;
    margin-left: 0;
  }
}


/*Blockchain*/

.blockchain {
  padding-top: 2rem;
  padding-bottom: 10rem;
  &__wrapper {
    position: relative;
  }
  &__image {
    position: absolute;
    top: 50%;
    left: -2%;
    transform: translateY(-50%);
    width: 40%;
    max-width: 32rem;
  }
  &__about {
    padding-left: 47%;
  }
  p {
    &:not(:last-of-type) {
      margin-bottom: 2rem;
    }
    &:not(.title) {
      span {
        color: @accent3-color;
      }
    }
    &:last-of-type {
      font-weight: 300;
      font-style: italic;
    }
    a {
      color: @accent2-color;
    }
  }
  &__title {
    font-size: 2rem;
    b {
      font-family: 'Poppins', 'Arial', sans-serif;
      font-weight: 800;
    }
  }
  &__bridges {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
    margin-bottom: 4rem!important;
    img {
      opacity: .45;
      max-width: 18%;
    }
  }
}


/*Difference*/

.difference {
  padding-top: 2rem;
  padding-bottom: 10rem;
  overflow: hidden;
  &__subtitle {
    padding-left: 7.5rem;
    background: url("../img/coin.png") no-repeat left center / 5rem auto;
  }
  &__paragraph {
    width: 70%;
    span {
      color: @accent1-color;
    }
  }
  &__banner {
    font-weight: 700;
    color: @shadow-color;
    font-style: italic;
    text-align: center;
    text-transform: uppercase;
    background-color: @accent1-color;
    padding: 1.6rem 11%;
    border: 1px solid @shadow-color;
    box-shadow: 4px 4px 0px @shadow-color;
    transform: rotate(-1deg);
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    span:not(.difference__spoiler) {
      font-size: 2rem;
      font-weight: 800;
      color: #EB467A;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        .difference__spoiler {
          display: block;
          opacity: 1;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }
    }
  }
  &__note {
    width: 70%;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 2rem;
  }
  &__list {
    &-item {
      width: 70%;
      position: relative;
      padding-left: 3rem;
      &::before {
        content: '';
        width: 1rem;
        height: 1rem;
        background-color: @light-color;
        border: 1px solid @shadow-color;
        box-shadow: 2px 2px 0px @shadow-color;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: .5rem;
      }
      &:not(:last-of-type) {
        margin-bottom: 2rem;
      }
    }
    &-item:not(.uk .difference__list-item) {
      &:nth-of-type(odd) {
        margin-left: auto;
      }
      &:nth-of-type(1) {
        padding-right: 8%;
        margin-right: 16%;
      }
      &:nth-of-type(2) {
        &::after {
          content: '';
          width: 5.2rem;
          height: 5.2rem;
          background: url("../img/coin-edge.png") no-repeat center / contain;
          position: absolute;
          left: 130%;
          top: 40%;
        }
      }
      &:nth-of-type(3) {
        &::after {
          content: '';
          width: 3.5rem;
          height: 3.5rem;
          background: url("../img/coin-edge.png") no-repeat center / contain;
          position: absolute;
          left: -30%;
          top: 40%;
          transform: rotate(-50deg);
        }
      }
      &:nth-of-type(5) {
        margin-right: 10%;
      }
      &:last-of-type {
        &::after {
          content: '';
          width: 3.5rem;
          height: 3.5rem;
          background: url("../img/coin-edge.png") no-repeat center / contain;
          position: absolute;
          left: 110%;
          top: 40%;
          transform: rotate(40deg);
        }
      }
    }
    span:not(.difference__spoiler) {
      color: @accent2-color;
      font-weight: 700;
      font-style: italic;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        .difference__spoiler {
          display: block;
          opacity: 1;
          top: 50%;
          left: 70%;
          transform: translateX(-50%) translateY(-50%);
        }
      }
    }
  }
  &__spoiler {
    width: 38rem;
    font-size: 1rem;
    font-weight: 300;
    font-style: italic;
    line-height: 140%;
    min-height: fit-content;
    color: @shadow-color;
    background-color: @light-color;
    padding: 1.2rem 2rem;
    border: 1px solid @shadow-color;
    box-shadow: 4px 4px 0px @shadow-color;
    position: absolute;
    bottom: 20%;
    z-index: 1;
    display: none;
    opacity: 0;
    transition: .3s;
    cursor: initial;
  }
}


/*Partners*/

.partners {
  padding-top: 2rem;
  padding-bottom: 10rem;
  &__subtitle {
    width: 100%;
    padding-right: 7.5rem;
    background: url("../img/coin.png") no-repeat right center / 5rem auto;
    justify-content: flex-end;
    text-align: right;
  }
  &__note {
    width: 70%;
    margin-left: auto;
    font-size: 2rem;
    line-height: 150%;
    font-weight: 700;
    font-style: italic;
    text-transform: uppercase;
    text-align: right;
    span {
      color: @accent1-color;
    }
  }
  &__item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    &:nth-of-type(1) {
      align-items: flex-start;
      margin-top: 3.5rem;
    }
    &:nth-of-type(2) {
      align-items: center;
      margin-top: -4rem;
    }
  }
  &__text {
    width: 52%;
    span {
      color: @accent1-color;
      font-weight: 700;
    }
    a:not(.partners__name, .uk .partners__button) {
      color: @accent2-color;
      font-weight: 700;
      text-decoration: underline;
      &:hover {
        opacity: .7;
      }
    }
  }
  &__name {
    display: inline-block;
    font-family: 'Wicked Mouse', 'Arial', sans-serif;
    font-size: 3rem;
    color: @text-color;
    letter-spacing: .1rem;
    text-shadow: 4px 4px 0px @shadow-color;
    margin-bottom: 3rem;
    &:hover {
      opacity: .7;
    }
  }
  &__image {
    display: block;
    //width: 38%;
    line-height: 0;
    &.mobile {
      display: none;
      width: 0;
    }
    img {
      width: 100%;
    }
  }
}


/*Who*/

.who {
  padding-top: 6rem;
  padding-bottom: 6rem;
  &__wrapper {
    flex-direction: column;
    align-items: center;
  }
  &__subtitle {
    padding-left: 7.5rem;
    background: url("../img/coin.png") no-repeat left center / 5rem auto;
    padding-right: 7.5rem;
    position: relative;
    &::after {
      content: '';
      width: 5rem;
      height: 5rem;
      background: url("../img/coin.png") no-repeat right center / contain;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &__text {
    text-align: center;
    width: 90%;
    a {
      font-weight: 700;
      color: @accent2-color;
      text-decoration: underline;
    }
  }
}


/*Timing*/

.timing {
  padding-top: 2rem;
  padding-bottom: 8rem;
  &__subtitle {
    padding-left: 7.5rem;
    background: url("../img/coin.png") no-repeat left center / 5rem auto;
  }
  &__container {
    margin-top: 3rem;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    &::after {
      content: '';
      width: 4px;
      height: ~'calc(100% - 2rem)';
      background-color: #564497;
      position: absolute;
      left: ~'calc(45.5% - .05rem)';
      top: 1rem;
    }
  }
  &__paragraph {
    width: 100%;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    &:not(:last-of-type) {
      margin-bottom: 3.5rem;
    }
  }
  &__date {
    font-family: 'Wicked Mouse', 'Arial', sans-serif;
    font-size: 2rem;
    line-height: 150%;
    letter-spacing: .1rem;
    text-align: right;
    text-shadow: 3px 3px 0px @shadow-color;
    display: block;
    width: 40%;
    &::before {
      content: '';
      width: 2rem;
      height: 2rem;
      position: absolute;
      left: 45.5%;
      top: .5rem;
      transform: translateX(-50%);
      background-color: @accent1-color;
      border: 1px solid @shadow-color;
      box-shadow: 2px 2px 0px @shadow-color;
      z-index: 2;
      border-radius: 50%;
    }
  }
  &__text {
    width: 50%;
    line-height: 3.5rem;
  }
  &__go {
    display: inline-block;
    font-weight: 700;
    padding-left: 50%;
    margin-top: 2.5rem;
    line-height: 130%;
    span {
      padding-right: 2.7rem;
      position: relative;
      &::after {
        content: '';
        width: 2rem;
        height: 2rem;
        background: url("../img/emoji.png") no-repeat center / contain;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  }
}

/*Terms*/

.terms-page,
.privacy-page {
  padding-top: 10rem;
  padding-bottom: 8rem;

  section {
    margin-bottom: 2rem;
  }

  p {
    color: @text-color;
    margin-bottom: 2rem;
  }

  .title {
    font-size: 2rem;
    font-family: 'Poppins','Arial',sans-serif;
    text-shadow: none;
    letter-spacing: normal;
    margin-bottom: 2rem;
  }

  li {
    color: @text-color;
    list-style-type: disc;
    list-style-position: inside;
    margin-bottom: 1rem;
  }
}

.text-uppercase {
  text-transform: uppercase;
}

/*Footer*/

.footer {
  padding-top: 5rem;
  padding-bottom: 24px;
  border-top: 4px solid #564497;
  &__wrapper {
    display: flex;
    justify-content: center;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    a {
      font-weight: 500;
      color: rgba(255,255,255,.3);
      line-height: 2.5;
      &:not(:last-of-type) {
        margin-right: 2rem;
      }
    }
  }
  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgba(255,255,255,.6);
    img {
      width: 5rem;
      margin-bottom: 1rem;
    }
    &:hover {
      opacity: .7;
    }
  }
}


/*Cookies*/

.cookies {
  background: @accent1-color;
  font-size: 1rem;
  line-height: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 5;
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__text {
    width: 80%;
    padding-right: 5%;
  }
  &__button {
    font-size: 1.2rem;
    display: inline-block;
    background: @main-color;
    color: @text-color;
    cursor: pointer;
    padding: 1rem 2rem;
    border-radius: 1.5rem;
  }
}


/*Animate*/

.banner {
  &__animate {
    margin-top: 3rem;
    &-title {
      width: fit-content;
      font-size: 1.2rem;
      text-align: center;
      letter-spacing: .15rem;
      margin-bottom: .3rem;
      position: relative;
      padding-right: 3rem;
      margin-left: auto;
      margin-right: auto;
      em {
        font-style: normal;
        font-size: .8rem;
      }
    }
    &-video {
      width: 100%;
      &.mobile {
        display: none;
        position: absolute;
        width: 0;
        height: 0;
        overflow: hidden;
      }
    }
    &-dots {
      position: absolute;
      left: calc(100% - 2.5rem);
      bottom: .5rem;
      display: flex;
      align-items: flex-end;
    }
    &-dot {
      display: block;
      width: .45rem;
      height: .45rem;
      background-color: @text-color;
      border-radius: 50%;
      box-shadow: 4px 4px 0px @shadow-color;
      margin-left: .2rem;
      margin-right: .1rem;
      animation: animate-dot 1.1s infinite ease-in-out;
      &.dot1 {

      }
      &.dot2 {
        animation-delay: -1s;
      }
      &.dot3 {
        animation-delay: -0.9s;
      }
    }
  }
}
@keyframes animate-dot {
  0%,
  40%,
  100% {
    width: .45rem;
    height: .45rem;
  }
  20% {
    width: .25rem;
    height: .25rem;
  }
}


/*Howtobuy*/

.how {
  padding-top: 6rem;
  padding-bottom: 10rem;
  min-height: 80vh;
  &__title {
    font-size: 3.5rem;
    line-height: initial;
    color: @text-color;
    display: flex;
    align-items: center;
    padding-left: 7.5rem;
    background: url("../img/coin.png") no-repeat left center / 5rem auto;
    min-height: 5rem;
  }

  &__title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4rem;

    .button {
      margin: 0;
    }
  }

  &__subtitle {
    width: 100%;
    padding-right: 7.5rem;
    background: url("../img/coin.png") no-repeat right center / 5rem auto;
    justify-content: flex-end;
    text-align: right;
    margin-bottom: 4.5rem;
  }
  &__button {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 25rem;
    font-size: 1.5rem;
    padding: 1.6rem 4.5rem 1.6rem 2.5rem;
    background-image: url("../img/arrow-down.svg");
    background-position: right 2.5rem center;
    background-size: 1rem;
    background-repeat: no-repeat;
    position: relative;
    z-index: 2;
    &.active {
      background-color: #826ccb;
      box-shadow: -4px -4px 0 @shadow-color;
    }
    &.selected {
      background: @accent1-color url("../img/refresh.svg") no-repeat right 1.5rem center / 1rem;
      box-shadow: 4px 4px 0 @shadow-color;
      padding: .8rem 1.2rem;
      text-align: left;
      path {
        fill: @main-color;
      }
      svg {
        margin-right: 1rem;
      }
      span {
        color: @main-color;
        margin-left: .5rem;
      }
    }
    em {
      font-style: initial;
    }
  }
  &__contract {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 3rem;
    >span {
      font-size: 2.6rem;
      font-weight: 800;
      color: @accent1-color;
      word-break: break-all;
      line-height: 150%;
    }
    &-copy {
      cursor: pointer;
      font-size: 1rem;
      color: rgba(255,255,255,.6);
      padding: 3.6rem 1rem 0 1rem;
      background: url("../img/copy-btn.svg") no-repeat center top / 3.5rem;
      span {
        &:nth-of-type(2) {
          display: none;
        }
      }
    }
  }
  &__instruction {
    background: url("../img/deco-arrow.png") no-repeat left 10% top 75%/ 4rem auto;
    p {
      margin-left: auto;
    }
  }
  &__pay {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 5rem;
    &-title {
      width: 100%;
      font-size: 1rem;
      color: @light-color;
      text-transform: uppercase;
    }
    &-wrapper {
      width: ~'calc(100% - 20rem)';
      display: flex;
      align-items: stretch;
      span {
        display: flex;
        align-items: center;
        font-weight: 500;
        color: @light-color;
        padding-left: 5.5rem;
        padding-right: 1.5rem;
        background: #564497 url("../img/eth-icon.svg") no-repeat left 1.5rem center / 3rem;
        border-radius: 0 1.5rem 1.5rem 0;
        border-left: 2px solid @main-color;
      }
    }
    &-input {
      width: 100%;
      font-family: 'Poppins', 'Arial', sans-serif;
      font-size: 2rem;
      font-weight: 700;
      text-align: right;
      color: @text-color;
      background-color: #564497;
      padding: .9rem 1.5rem .9rem 1.5rem;
      border-radius: 1.5rem 0 0 1.5rem;
      -moz-appearance: textfield;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
      &::placeholder {
        color: #826CCB;
      }
    }
    &-button {
      background-color: @accent1-color;
      cursor: pointer;
      transition: .3s;
      margin-left: 0;
      margin-right: 0;
      &:hover {
        background-color: #e3ba5f;
      }
    }
    &-thanks {
      width: 100%;
      font-style: italic;
      color: @light-color;
      margin-top: 1rem;
      span {
        display: block;
        font-weight: 700;
        color: #FFA987;
        text-transform: uppercase;
      }
      a {
        color: @accent2-color;
        font-weight: 700;
        text-decoration: underline;
        &:hover {
          opacity: .7;
        }
      }
    }
  }
  &__paragraph {
    color: @text-color;
    width: 79%;
    //margin: 0 auto;
    margin-bottom: 3rem;
    a {
      color: @accent3-color;
      font-weight: 700;
      text-decoration: underline;
      &:hover {
        opacity: .7;
      }
      &.yellow {
        color: @accent1-color;
      }
    }
    span {
      font-weight: bold;
      color: @accent1-color;
    }
    strong {
      font-weight: bold;
      color: @accent2-color;
    }
    i {
      font-weight: 300;
    }
  }
  &__banner {
    color: @text-color;
    width: 52%;
    margin-left: auto;
    background-color: #826CCB;
    border: 1px solid @shadow-color;
    box-shadow: 4px 4px 0px @shadow-color;
    padding: 2rem 1.5rem;
    margin-bottom: 12rem;
    transform: rotate(-2deg);
    a {
      color: @accent1-color;
      font-weight: 700;
      text-decoration: underline;
    }
    strong {
      color: @accent2-color;
      font-weight: 700;
    }
    i {
      font-weight: 300;
    }
  }
  &__price {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 4rem;
    margin-top: 2rem;
    flex-wrap: wrap;
    strong {
      margin-left: auto;
      font-style: italic;
      font-weight: 300;
      color: @text-color;
      opacity: .8;
    }
    > span {
      color: @text-color;
      &:first-of-type {
        width: 50%;
        font-size: 3rem;
        text-transform: uppercase;
        line-height: 150%;
      }
      &:last-of-type {
        width: 50%;
        text-align: right;
        font-size: 3.2rem;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 150%;
      }
    }
  }
  &__telegram {
    font-size: 2rem;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 150%;
    a {
      color: #32B4FF;
      text-decoration: underline;
      &:hover {
        opacity: .7;
      }
    }
  }
  &__crypto {
    display: none;
    position: relative;
    z-index: 2;
    &.active {
      display: block;
    }
    &-list {
      display: grid;
      grid-template-columns: repeat(11, 1fr);
      background-color: #4c3892;
      margin-top: 2.5rem;
      &::before {
        content: '';
        border: 0 solid transparent;
        border-right-width: .8rem;
        border-left-width: .8rem;
        border-bottom: 1.2rem solid #4c3892;
        position: absolute;
        left: 50%;
        bottom: 100%;
        transform: translateX(-50%);
      }
    }
    &-item {
      color: @text-color;
      flex-grow: 1;
      font-size: .65rem;
      line-height: initial;
      text-align: center;
      padding: 1rem .3rem .3rem;
      cursor: pointer;
      &:not(:last-of-type) {
        border-right: 1px solid #5f4ca8;
      }
      &:hover {
        background-color: @accent1-color;
        color: @shadow-color;
        path {
          fill: @shadow-color;
        }
      }
      span {
        color: @light-color;
      }
      svg {
        display: block;
        width: 3rem;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: .7rem;
      }
    }
    &-form {
      display: none;
      justify-content: flex-end;
      margin-top: .5rem;
      &.active {
        display: flex;
      }
      &.send {
        input {
          outline: 1px solid @accent1-color;
        }
        button {
          cursor: pointer;
        }
        path {
          fill: @accent3-color;
        }
      }
    }
    &-input {
      font-size: .7rem;
      color: @light-color;
      background-color: #4c3892;
      border-radius: .3rem;
      padding: .5rem .8rem;
      max-width: 18rem;
      width: 100%;
      &::-moz-placeholder {
        color: @light-color;
      }
      &::-webkit-input-placeholder {
        color: @light-color;
      }
    }
    &-check {
      width: 1.5rem;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
    }
    &-close {
      display: none;
      width: 4rem;
      height: 4rem;
      background: url("../img/close.svg") no-repeat center / contain;
      position: absolute;
      right: 1rem;
      top: 1rem;
    }
  }
  &__steps {
    display: none;
    justify-content: space-between;
    margin-top: 5rem;
    position: relative;
    &::before,
    &::after {
      content: '';
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: center;
      position: absolute;
    }
    &::before {
      width: 7.8rem;
      height: 8.5rem;
      background-image: url("../img/steps-arrow1.svg");
      bottom: 100%;
      right: 70%;
    }
    &::after {
      width: 14.5rem;
      height: 11.2rem;
      background-image: url("../img/steps-arrow2.svg");
      bottom: -10%;
      left: 65%;
    }
    &.active {
      display: flex;
      flex-wrap: wrap;
    }
    &-name {
      color: @text-color;
      font-weight: bold;
      span {
        font-weight: normal;
        opacity: .5;
      }
    }
    &-item {
      font-size: 1.4rem;
      &:not(:last-of-type) {
        color: @shadow-color;
        width: 29%;
        background-color: @light-color;
        border: 1px solid @shadow-color;
        box-shadow: 4px 4px 0px @shadow-color;
        padding: 1.5rem;
      }
      &:nth-of-type(1), &:nth-of-type(2) {
        position: relative;
        &::after {
          content: '';
          width: 2.5rem;
          height: 2.5rem;
          background: url("../img/deco-arrow.png") no-repeat center/ 100% auto;
          position: absolute;
          right: -4rem;
          top: 50%;
          transform: rotate(-90deg) translateX(40%);
        }
      }
      &:last-of-type {
        font-size: 3rem;
        line-height: initial;
        font-family: 'Wicked Mouse', 'Arial', sans-serif;
        text-transform: uppercase;
        text-shadow: 4px 4px 40px #5f4ca8, 4px 4px 0 @shadow-color;
        color: @accent1-color;
        width: 100%;
        text-align: center;
        margin-top: 5rem;
      }
      i {
        font-weight: 300;
      }
      a {
        color: #018777;
        font-weight: bold;
        text-decoration: underline;
      }
      svg {
        display: none;
      }
    }
  }
}

.how__pay-error {
  color: red;
  text-align: center;
  margin-top: 24px;
}
.how__pay-loading {
  color: @text-color;
  text-align: center;
  margin-top: 24px;
}

/*Charity*/

.charity {
  padding-top: 6rem;
  overflow: hidden;
  &__title {
    font-size: 3rem;
    margin-bottom: 2.8rem;
    span {
      display: block;
    }
    em {
      font-size: 2rem;
      font-style: initial;
    }
  }
  &__paragraph {
    color: @text-color;

    &:not(:last-of-type) {
      margin-bottom: 10rem;
    }
    &:last-of-type {
      margin-bottom: 5rem;
    }
    &:nth-of-type(1) {
      width: 70%;
      position: relative;
      &::before {
        content: '';
        width: 22rem;
        height: 23rem;
        position: absolute;
        bottom: 0;
        left: 110%;
        background: url("../img/charity-bg1.png") no-repeat center bottom / auto 100%;
      }
    }
    &:nth-of-type(2) {
      width: 67%;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      &::after {
        content: '';
        width: 15rem;
        height: 17rem;
        position: absolute;
        top: 50%;
        right: 100%;
        transform: translateY(-50%);
        background: url("../img/charity-bg2.png") no-repeat center bottom / auto 100%;
      }
      &::before {
        content: '';
        width: 7.5rem;
        height: 7.5rem;
        position: absolute;
        top: 50%;
        left: 105%;
        transform: translateY(-50%);
        background: url("../img/charity-bg3.png") no-repeat center bottom / auto 100%;
      }
    }
    &:nth-of-type(3) {
      width: 52%;
      text-align: right;
      margin-left: auto;
      position: relative;
      &::after {
        content: '';
        width: 26rem;
        height: 22rem;
        position: absolute;
        top: 0;
        right: 120%;
        background: url("../img/charity-bg4.png") no-repeat center bottom / auto 100%;
      }
    }
    &:nth-of-type(4) {
      width: 68%;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
    span {
      font-weight: bold;
      color: @accent1-color;
    }
    strong {
      font-weight: bold;
      color: @accent2-color;
    }
  }
  &__subtitle {
    font-family: 'Wicked Mouse', 'Arial', sans-serif;
    font-size: 2rem;
    color: @text-color;
    letter-spacing: .3rem;
    line-height: 160%;
    text-shadow: 4px 4px 0px @shadow-color;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-bottom: 5rem;
    position: relative;
    &::before,
    &::after {
      content: '';
      width: 12.5rem;
      height: 18.7rem;
      position: absolute;
      bottom: 0;
      background-repeat: no-repeat;
      background-position: center bottom;
      background-size: auto 100%;
    }
    &::before {
      background-image: url("../img/charity-hands1.png");
      right: 105%;
    }
    &::after {
      background-image: url("../img/charity-hands2.png");
      left: 105%;
    }
    em {
      color: @accent1-color;
      font-style: initial;
    }
    span {
      font-size: 1.5rem;
    }
    b {
      font-family: 'Poppins', 'Arial', sans-serif;
      font-weight: bold;
    }
  }
}

/*Photoapp*/

.photoapp {
  &__banner {
    position: relative;
    overflow: hidden;
    z-index: 1;
    .wrapper {
      display: flex;
      flex-direction: column;
      padding-top: 7.5rem;
      padding-right: 23%;
      background: url("../img/photoapp-deco2.png") no-repeat left top / 17rem;
      &::after {
        content: '';
        width: 38rem;
        height: 33rem;
        background: url("../img/photoapp-banner.png") no-repeat center / contain;
        position: absolute;
        left: 56%;
        top: 5rem;
      }
    }
  }
  &__title {
    margin-bottom: 1.3rem;
    position: relative;
    z-index: 1;
    span {
      color: #fec202;
    }
  }
  &__about {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 2rem;
    width: 90%;
    position: relative;
    z-index: 1;
  }
  &__button {
    background-color: #fec202;
    margin-left: initial;
    margin-bottom: 1.5rem;
    font-weight: bold;
    font-size: 1.3rem;
    &:hover {
      background-color: @light-color;
    }
  }
  &__note {
    width: 100%;
    font-size: 1.2rem;
    font-weight: bold;
    color: #fec202;
    margin-bottom: 3rem;
  }
  &__important {
    font-size: 1.5rem;
    font-style: italic;
    background: url("../img/photoapp-import.svg") no-repeat right bottom / cover;
    color: @shadow-color;
    text-align: center;
    padding: 3rem 5rem 5.5rem 3rem;
    position: relative;
    &::before {
      content: '';
      width: 3.2rem;
      height: 5.3rem;
      background: url("../img/photoapp-deco1.svg") no-repeat center / contain;
      position: absolute;
      right: 105%;
      top: 3rem;
    }
    &::after {
      content: '';
      width: 100%;
      height: 12px;
      background: url("../img/photoapp-dashed.svg") no-repeat center / contain;
      position: absolute;
      left: 0;
      top: -6px;
    }
  }
  &__info {
    margin-top: -5rem;
    padding-top: 7.5rem;
    background-color: @text-color;
    &-wrapper {
      padding-left: 28%;
      padding-bottom: 7.5rem;
      position: relative;
      &::before {
        content: '';
        width: 44%;
        height: 100%;
        background: url("../img/photoapp-bg.png") no-repeat center bottom / 100% auto;
        position: absolute;
        right: 62%;
        bottom: 3rem;
      }
    }
  }
  &__discover {
    background-color: #f8f5ff;
    padding: 6rem 3.5rem 3.5rem;
    border-radius: 3rem;
    margin-bottom: 3.7rem;
    position: relative;
    &::before {
      content: '';
      width: 4rem;
      height: 4rem;
      background: url("../img/coin.png") no-repeat center / 100% auto;
      position: absolute;
      right: -2%;
      top: 2rem;
    }
    &::after {
      content: '';
      width: 7rem;
      height: 6.7rem;
      background: url("../img/photoapp-deco4.png") no-repeat center / 100% auto;
      position: absolute;
      right: 2%;
      bottom: -4rem;
    }
    .photoapp__description {
      position: relative;
      &::before {
        content: '';
        width: 5.6rem;
        height: 6.7rem;
        background: url("../img/photoapp-deco3.png") no-repeat center / contain;
        position: absolute;
        left: -7rem;
        top: 0;
      }
    }
  }
  &__subtitle {
    font-size: 3.1rem;
    line-height: 100%;
    color: @shadow-color;
    margin-bottom: 2rem;
    font-weight: 800;
  }
  &__description {
    color: @shadow-color;
    margin-bottom: 1.2rem;
  }
  &__more {
    font-weight: bold;
    color: #fec202;
    padding-right: 3rem;
    position: relative;
    background: url("../img/photoapp-more.svg") no-repeat right center / 2rem;
  }
  &__btn {
    display: block;
    background-color: #a68df0;
    color: @text-color;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    max-width: 20rem;
    border-radius: 3rem;
    padding: 1.1rem;
    margin-top: 2rem;
  }
}

/*FAQ*/

.faq {
  padding-top: 12rem;
  padding-bottom: 5rem;
  min-height: 80vh;
  &__subtitle {
    padding-left: 7.5rem;
    background: url("../img/coin.png") no-repeat left center / 5rem auto;
    margin-bottom: 4.5rem;
  }
  &__item {
    width: 100%;
    border-bottom: 4px solid #564497;
    &.active {
      .faq__question {
        &::after {
          content: '–';
        }
      }
      .faq__answer {
        max-height: 1000rem;
        padding-bottom: 1px;
        padding-top: 2.5rem;
      }
    }
  }
  &__question {
    font-size: 2rem;
    font-weight: 700;
    padding: 2.5rem 10%;
    position: relative;
    cursor: pointer;
    &::after {
      content: '+';
      font-family: Monospaced, System-ui;
      font-size: 4rem;
      text-shadow: 3px 3px 0px @shadow-color;
      -webkit-text-stroke: 1px @shadow-color;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &__answer {
    font-weight: 300;
    font-size: 1rem;
    line-height: 160%;
    background-color: #564497;
    padding: 0 10% 0 10%;
    max-height: 0;
    overflow: hidden;
    transition: .3s;
    p {
      margin-bottom: 2.5rem;
    }
    ul {
      margin-bottom: 2.5rem;
    }
    li {
      position: relative;
      padding-left: 6rem;
      &::before {
        content: '';
        width: .5rem;
        height: .5rem;
        background-color: @light-color;
        border: 1px solid @shadow-color;
        box-shadow: 2px 2px 0px @shadow-color;
        border-radius: 50%;
        position: absolute;
        left: 3rem;
        top: .5rem;
      }
      li {
        padding-left: 4rem;
        &::before {
          width: .5rem;
          height: .5rem;
          background-color: @text-color;
          border: none;
          box-shadow: none;
          left: 2rem;
        }
      }
    }
    a {
      color: @accent3-color;
      font-weight: 700;
      text-decoration: underline;
      &:hover {
        opacity: .7;
      }
    }
  }
  &__form {
    width: 100%;
    margin-top: 7rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    &-title {
      width: 100%;
      font-size: 2rem;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 2.5rem;
    }
    &-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &-item {
      &:not(:last-of-type) {
        width: 48%;
        margin-bottom: 1.5rem;
      }
      &:last-of-type {
        width: 100%;
      }
      span {
        font-size: 1rem;
        color: @light-color;
        text-transform: uppercase;
        display: block;
        width: 100%;
      }
      input, textarea {
        width: 100%;
        font-size: 1.8rem;
        color: @text-color;
        padding: .7rem 1.5rem;
        border-radius: 1.4rem;
        background-color: #564497;
        &::placeholder {
          color: #826CCB;
        }
      }
      textarea {
        height: 12.5rem;
        resize: none;
      }
    }
    &-button {
      margin-top: 2.5rem;
    }
    &-thanks {
      text-align: center;
      background-color: #826CCB;
      border: 1px solid @shadow-color;
      box-shadow: 4px 4px 0px @shadow-color;
      padding: 2rem 1.5rem;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}


/*Pause*/

.pause {
  overflow: hidden;
  background: url("../img/close-page.png") no-repeat center / 80% auto;
  &__wrapper {
    padding-top: 12rem;
    padding-bottom: 6rem;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-between;
  }
  &__banner {
    width: 102vw;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: @accent1-color;
    color: @shadow-color;
    text-align: center;
    font-weight: 700;
    border: 1px solid @shadow-color;
    box-shadow: 4px 4px 0px @shadow-color;
    position: relative;
    left: 50%;
    transform: rotate(1deg) translateX(-50%);
    margin-bottom: 4rem;
    p {
      display: inline-block;
      padding-left: 15%;
      padding-right: 15%;
    }
    span {
      font-size: 2rem;
      font-style: italic;
    }
  }
  &__title {
    text-align: center;
    margin-bottom: 1.5rem;
  }
  &__telegram {
    width: 100%;
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    line-height: 150%;
    margin-bottom: 2rem;
    a {
      color: #32B4FF;
      text-decoration: underline;
      &:hover {
        opacity: .7;
      }
    }
  }
  &__price {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-text {
      width: 60%;
      span {
        font-size: 2rem;
        text-transform: uppercase;
        line-height: 150%;
      }
      strong {
        display: block;
        font-size: 1rem;
        text-transform: none;
        color: @light-color;
      }
    }
    &-number {
      text-align: right;
      font-size: 3.5rem;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 150%;
      span {
        color: @accent3-color;
        display: block;
      }
    }
  }
}


/*Ukraine*/

.uk {

  .banner {
    background-position: left 40vw top -5rem;
    padding-bottom: 13rem;
    &__title {
      strong {
        font-size: 2.2rem;
      }
      b {
        font-family: 'Poppins', 'Arial', sans-serif;
        font-weight: 800;
      }
    }
    &__text {
      font-size: 1.8rem;
      line-height: 150%;
      span {
        text-shadow:4px 0px 5px rgba(0,0,0,.2);
        color: @text-color;
        text-transform: uppercase;
      }
    }
  }

  .about, .fair {
    padding-top: 6rem;
    padding-bottom: 6rem;
    &__wrapper {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .buy {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .difference {
    padding-top: 6rem;
    padding-bottom: 6rem;
    &__subtitle {
      font-size: 2.2rem;
      line-height: 150%;
    }
    &__paragraph {
      width: 100%;
      margin-bottom: 2.5rem;
    }
    &__list {
      &-item {
        width: 100%;
        display: flex;
        align-items: center;
      }
      i {
        font-style: normal;
        width: 65%;
      }
    }
    &__button {
      margin-top: 0;
    }
  }

  .fair {
    &__text {
      margin-bottom: 0;
    }
  }

  .partners {
    padding-top: 6rem;
    padding-bottom: 6rem;
    &__wrapper {
      flex-direction: column;
      position: relative;
    }
    &__image {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 32%;
      max-width: 32rem;
    }
    &__subtitle {
      width: 55%;
      padding-left: 7.5rem;
      padding-right: 0;
      background: url("../img/coin.png") no-repeat left center / 5rem auto;
      align-self: flex-end;
      display: flex;
      justify-content: flex-start;
    }
    &__text {
      width: 55%;
      align-self: flex-end;
      display: flex;
      flex-direction: column;
      span {
        color: @accent1-color;
        font-weight: 700;
      }
    }
    &__button {
      margin-left: 0;
      margin-top: 3rem;
    }
  }

}

.ukraine {
  &__wrapper {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
    border-top: 4px solid #564497;
    border-bottom: 4px solid #564497;
    flex-direction: column;
    align-items: center;
  }
  &__tag {
    font-size: 3.5rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 2.5rem;
  }
  &__button {
    background-color: @accent1-color;
    &:hover {
      background-color: #e3ba5f;
    }
  }
}

.how__wrapper {
  display: block;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 24px;
    font-weight: 800;
    color: @text-color;
    line-height: 1.67;
    margin-bottom: 40px;
  }

  //h2 {
  //  font-size: 16px;
  //  font-weight: 600;
  //  line-height: 1.5;
  //  color: #b39ef2;
  //  text-transform: uppercase;
  //}
}

.vote__form_balance {
  h2 {
    text-align: center;
    margin-bottom: 8px;
  }

  img {
    width: 48px;
    margin-right: 8px;
    margin-top: 8px;
  }

  p {
    display: flex;
    align-items: flex-start;
    word-break: break-word;
    margin-bottom: 40px;
  }

  .balance {
    font-size: 48px;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: 2.4px;
    text-align: right;
  }
}

.vote__form {
  display: flex;
  flex-direction: column;

  h2 {
    text-align: left;
    margin-bottom: 24px;
  }

  input[type="radio"] {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 0;
    height: 0;
    width: 0;

    &::after {
      content: '';
      display: block;
      width: 32px;
      height: 32px;
      border-radius: 72px;
      background-color: #564497;
      position: absolute;
      top: 0;
      left: 0;
      transform: translateY(-50%);
    }

    &:checked {
      &::before {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        border-radius: 72px;
        background-color: @accent2-color;
        position: absolute;
        top: 0;
        left: 8px;
        transform: translateY(-50%);
        z-index: 5;
      }
    }
  }

  label {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.67;
    letter-spacing: 1.2px;
    position: relative;
    padding-left: 56px;
    margin-bottom: 24px;
  }

  input:checked + span {
    color: @accent2-color;
  }

  input[type="text"] {
    width: 100%;
    height: 72px;
    padding: 0 24px;
    border-radius: 24px;
    background-color: #564497;
    font-size: 24px;
    font-weight: 600;
    color: @light-color;

    &::placeholder {
      font-size: 24px;
      font-weight: 600;
      color: @light-color;
      text-transform: uppercase;
    }
  }

  button {
    width: 240px;
    height: 72px;
    margin-top: 8px;
  }
}

.vote__form_message_success {
  font-size: 16px;
  font-weight: 500;
  font-style: italic;
  color: @light-color;
  line-height: 1.5;
  text-align: center;
  margin-top: 16px;

  a {
    font-weight: bold;
    color: @accent2-color;
  }
}

.vote__form_message_error {
  color: red;
  text-align: center;
  margin-top: 24px;
}

.vote__form_loading {
  text-align: center;
  margin-top: 24px;
}

.vote-page {
  .how__wrapper {
    max-width: 800px;
    position: relative;
  }

  .how {
    display: flex;
  }
}

.vote__auth_wrapper {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.vote__auth_loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
  border: solid 4px @light-color;
  border-radius: 50%;
  box-sizing: border-box;

  svg {
    width: 120px;
    height: 120px;
  }

  &-1,
  &-2 {
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    border-radius: 50%;
    left: -4px;
    top: -4px;
    position: relative;

    &:before,
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      border: 4px solid @light-color;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      box-sizing: border-box;
      animation: spinner 8s linear infinite;
    }
  }

  &-1:after {
    animation-delay: 0;
  }

  &-1:before {
    animation-delay: 2s;
  }

  &-2:after {
    animation-delay: 4s;
  }

  &-2:before {
    // content: none;
    animation-delay: 6s;
  }

  &-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

@keyframes spinner {
  0% {
    width: 100%;
    height: 100%;
    opacity: 1;
  }

  80% {
    opacity: 0;
  }

  100% {
    width: 300%;
    height: 300%;
    opacity: 0;
  }
}

.vote__auth {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h3 {
    font-size: 56px;
    font-weight: 800;
    line-height: 1.43;
    text-transform: uppercase;
  }

  p {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.67;
    margin-top: 24px;
    span {
      font-weight: bold;
    }
  }

  .button {
    width: 240px;
    height: 72px;
    margin-top: 64px;
  }
}

.vote__download_metamask_wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    font-size: 56px;
    font-weight: 800;
    color: @text-color;
    line-height: 1.43;
    text-transform: uppercase;

    span {
      color: #f6851b;
    }
  }

  p {
    font-size: 24px;
    font-weight: 500;
    color: @text-color;
    line-height: 1.67;
    margin-top: 24px;

    span {
      font-weight: bold;
    }
  }

  .button {
    width: 240px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @accent1-color;
    margin-top: 64px;
  }
}

.popup-show {
  overflow: hidden;
}

.popup-container {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;

  &_wallet {
    .popup {
      border-radius: 16px;
      background-color: #826ccb;
      box-shadow: none;
      border: none;
      padding: 24px 35px 40px;

      &__title {
        font-family: 'Poppins', 'Arial', sans-serif;
        font-size: 24px;
        margin-bottom: 24px;
        line-height: 40px;
        text-shadow: none;
      }

      &__close {
        right: 6px;
        top: -33px;
        width: 30px;
        height: 30px;

        svg {
          fill: #fff;
        }
      }

      &__btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #b39ef2;
        border: solid 1px #000;
        box-shadow: 4px 4px 0 0 #000;
        width: 100%;
        height: auto;
        padding: 24px 24px 16px;

        &:hover {
          background-color: #ffd06a;
          box-shadow: inset 4px 4px 0 0 #000;
        }

        img {
          height: 88px;
          margin-bottom: 24px;
        }

        h4 {
          font-size: 24px;
          font-weight: bold;
          line-height: 1.67;
          margin: 0;
        }

        p {
          font-size: 16px;
          font-weight: 500;
          line-height: 1.5;
          text-align: center;
          color: #564497;
        }
      }
    }
  }
}

.popup {
  max-width: 880px;
  width: 90%;
  border-radius: 2px;
  box-shadow: 4px 4px 0 0 @shadow-color;
  border: solid 1px @shadow-color;
  background-color: #826ccb;
  box-sizing: border-box;
  position: relative;
  padding: 56px 130px 64px;

  &__title {
    font-family: 'Wicked Mouse', 'Arial', sans-serif;
    font-size: 48px;
    color: @text-color;
    line-height: 1.67;
    letter-spacing: 2.4px;
    text-transform: uppercase;
    text-shadow: 4px 4px 40px #5f4ca8, 4px 4px 0 @shadow-color;
    text-align: center;
    margin-bottom: 48px;

    span {
      color: @accent1-color;
    }

    .popup__title_mark {
      font-family: 'Poppins', 'Arial', sans-serif;
      font-weight: 800;
      color: @text-color;
      font-size: 48px;
      line-height: 1.67;
      letter-spacing: 2.4px;
      text-transform: uppercase;
      text-shadow: 4px 4px 40px #5f4ca8, 4px 4px 0 @shadow-color;
    }
  }

  &__btns-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__btn {
    font-family: 'Poppins', 'Arial', sans-serif;
    font-size: 25px;
    font-weight: 600;
    letter-spacing: 0.75px;
    color: @shadow-color;
    width: 240px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    border-radius: 24px;
    box-shadow: 4px 4px 0 0 @shadow-color;
    border: solid 1px @shadow-color;
    background-color: #fec202;
    cursor: pointer;
    transition: .2s;
    margin: 0 24px;

    &:hover {
      position: relative;
      top: 2px;
      left: 2px;
      box-shadow: 2px 2px 0 0 @shadow-color;
      background-color: @light-color;
      transition: .2s;
    }
  }

  &__close {
    position: absolute;
    right: 6px;
    top: 6px;
    background: none;
    width: 40px;
    height: 40px;
    cursor: pointer;
    padding: 4px;

    svg {
      width: 100%;
      height: 100%;
    }

    &:hover {
      opacity: 0.6;
    }
  }
}

// --

.dapp-deeplink-container {
  height: 72px;
  width: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  padding-right: 44px;

  &.shown {
    display: flex;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to bottom, rgba(95, 76, 168, 0) 0%, #5f4ca8 100%);
  }

  .dapp-deeplink-button {
    font-size: 12px;
  }

  img {
    width: 40px;
    margin-top: 6px;
    margin-right: 4px;
  }
}

.dapp-deeplink-button {
  position: relative;
  z-index: 10;
  width: 100%;
  max-width: 240px;
  background-color: @accent1-color;
  margin: 0;
}

.js-place-time {
  font-weight: 300;
  font-style: italic;
}

.howtobuy_click_price {

  >span {
    color: @accent3-color;
  }
  strong {
    font-size: 1.5rem;
    line-height: initial;
    display: block;
    font-style: italic;
    font-weight: 300;
  }
}

.network_prod,
.network_debug,
.login,
.vote__zero_balance_wrapper p,
.vote__auth p,
.vote__form_balance h2,
.vote__form_balance p,
.vote__form h2,
.vote__form label,
.vote__form_loading {
  color: @text-color;
}

.mb-0 {
  margin-bottom: 0;
}

.dropdown-title {
  margin-top: 4rem;
}

.walletconnect-modal__footer {
  justify-content: flex-start !important;
  overflow: auto !important;
  padding-bottom: 12px !important;
}

.walletconnect-connect__button__text {
  line-height: 1.25 !important;
}

.walletconnect-qrcode__base {
  overflow: auto !important;
  display: flex !important;
  align-items: center !important;
  padding-top: 60px !important;
}

.walletconnect-modal__base {
  width: 100% !important;
  transform: none !important;
  top: 0 !important;
  padding-bottom: 10px !important;
}

@import "media-queries";
