/*Var*/

@main-color: #5f4ca8;
@light-color: #B39EF2;
@text-color: #ffffff;
@accent1-color: #ffd06a;
@accent2-color: #ff8db1;
@accent3-color: #00B8A2;
@shadow-color: #000000;

@media (max-width: 1300px) {

  html {
    font-size: 14px;
  }
  .wrapper {
    width: 90%;
  }
  .header {
    padding-left: 0;
    padding-right: 0;
  }

  /*Banner*/

  .banner {
    &__deco {
      div {
        &:nth-of-type(2) {
          right: 2%;
        }
        &:nth-of-type(3) {
          right: 4%;
        }
        &:nth-of-type(5) {
          right: 4%;
        }
      }
    }
  }

  /*Difference*/

  .difference {
    &__list {
      &-item {
        span:not(.difference__spoiler):hover {
          .difference__spoiler {
            left: 50%;
          }
        }
      }
    }
  }

  /*Footer*/

  .footer {
    &__wrapper {
      width: 90%;
    }
  }

}

@media (max-width: 1100px) {

  /*Header*/

  .header {
    &__menu {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      background-color: @light-color;
      padding: 20vh 5vw 5vh 5vw;
      flex-direction: column;
      align-items: flex-start;
      min-height: 100vh;
      width: 45vw;
      margin-left: auto;
      margin-right: auto;
      z-index: 2;
      &::after {
        content: '';
        width: 100%;
        height: 100vh;
        position: absolute;
        left: 0;
        top: 0;
        background-color: @light-color;
      }
      &::before {
        content: '';
        width: 100vw;
        height: 100vh;
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(0,0,0,.3);
      }
      &-item {
        position: relative;
        z-index: 2;
        &:not(:last-of-type) {
          margin-bottom: 2rem;
        }
        &:not(:first-of-type) {
          margin-left: 0;
        }
      }
      a {
        color: @shadow-color;
        font-weight: 700;
      }
    }
    &.active {
      background-color: #5f4ca8;
      border-bottom: 4px solid #564497;
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;

      .header__menu {
        display: flex;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100vh;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }
      .header__logo {
        height: 3.5rem;
        background: #b39ef2;
      }
    }
  }
  .btn-menu {
    display: flex;
    transition: .3s;
    z-index: 3;
  }

  /*Banner*/

  .banner {
    &:not(.uk .banner) {
      background-size: auto 100%;
      background-position: left 22vw center;
    }
    &__flag {
      top: 61%;
    }
  }

  /*About*/

  .about {
    &__no {
      font-size: 3rem;
    }
  }

  /*Blockchain*/

  .blockchain {
    &__image {
      left: -18%;
      width: 100%;
    }
  }

  /*Difference*/

  .difference {
    &__list {
      &-item {
        &:nth-of-type(1) {
          padding-right: 0;
          margin-right: 8%;
        }
        &:nth-of-type(5) {
          margin-right: 0;
        }
      }
    }
  }

  /*Partners*/

  .partners {
    &__item {
      &:nth-of-type(2) {
        margin-top: 2rem;
      }
    }
  }

  /*Howtobuy*/

  .how {
    &__price {
      strong {
        display: block;
      }
    }
  }

  /*Pause*/

  .pause {
    background-size: 120% auto;
    &__wrapper {
      justify-content: space-around;
    }
  }

  /*Ukraine*/

  .uk {
    .banner {
      background-size: auto 90%;
      background-position: left 20vw top 0;
    }

    .partners {
      &__image {
        width: 40%;
        left: -3rem;
      }
      &__subtitle {
        width: 65%;
      }
    }
  }

  .vote__form_balance {
    p {
      margin-bottom: 48px;
    }

    .balance {
      font-size: 24px;
      line-height: 1.33;
      letter-spacing: 1.2px;
    }

    img {
      width: 25px;
      margin-right: 4px;
      margin-top: 2px;
    }
  }

  .vote__form_balance h2 {
    margin-bottom: 16px;
  }

  .vote__form {
    width: 100%;

    h2 {
      text-align: center;
      margin-bottom: 16px;
    }

    label {
      word-break: break-all;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.33;
      letter-spacing: 0.6px;
      padding-left: 40px;
      margin-bottom: 30px;
    }

    input[type="text"] {
      height: 48px;
      padding: 0 16px;
      border-radius: 16px;
      font-size: 12px;

      &::placeholder {
        font-size: 12px;
      }
    }

    button {
      font-size: 16px;
      height: 56px;
      margin-top: 18px;
    }
  }

  .vote__form_message_success {
    font-size: 12px;
    max-width: 230px;
    margin: 16px auto 0;
  }

  .how__wrapper {
    h1 {
      font-size: 16px;
      line-height: 1.5;
    }
  }

  .vote-page {
    .how {
      padding-bottom: 48px;
    }
  }

  .vote__auth {
    h3 {
      font-size: 24px;
      line-height: 1.67;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
    }

    .button {
      height: 56px;
      margin-top: 48px;
    }
  }

  .vote__download_metamask_wrapper {
    h3 {
      font-size: 24px;
      line-height: 1.67;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
    }

    .button {
      height: 56px;
      margin-top: 48px;
    }
  }

}

@media (max-width: 991px) {
  .banner {
    &__deco {
      display: none;
    }
  }

  .footer {
    padding-bottom: 6rem;
  }
}

@media (max-width: 900px) {

  html {
    font-size: 13px;
  }
  .title {
    font-size: 2.4rem;
  }
  .subtitle {
    font-size: 3rem;
  }

  /*Banner*/

  .banner {
    padding-bottom: 8rem;
    min-height: 70vh;
    &:not(.uk .banner) {
      background-position: left 15vw center;
    }
    &__about {
      width: 80%;
    }
  }

  /*About*/

  .about {
    &__card {
      flex-direction: column-reverse;
      align-items: center;
    }
    &__no {
      width: initial;
      margin-bottom: 2rem;
      margin-left: 0;
      span {
        display: block;
      }
    }
    p {
      width: 100%;
    }
    &__note {
      br {
        display: none;
      }
      .wrapper {
        width: 90%;
      }
    }
    .chart {
      td {
        font-size: 1.7rem;
      }
    }
  }

  /*Aim*/

  .aim {
    padding-bottom: 8rem;
    &__wrapper {
      justify-content: center;
    }
    &__text {
      width: 100%;
      margin-bottom: 3rem;
    }
    &__chart {
      width: 80%;
    }
    &__button {
      &:not(.mobile) {
        display: none;
      }
      &.mobile {
        display: inline-block;
      }
    }
  }

  /*Buy*/

  .buy {
    padding-bottom: 8rem;
  }

  /*Blockchain*/

  .blockchain {
    padding-bottom: 8rem;
    &__about {
      padding-left: 0;
    }
    &__image {
      display: none;
    }
  }

  /*Difference*/

  .difference {
    padding-bottom: 8rem;
    &__banner {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  /*Partners*/

  .partners {
    padding-bottom: 8rem;
  }

  /*Timing*/

  .timing {
    &__container {
      width: 100%;
      &::after {
        left: calc(50% - .05rem);
      }
    }
    &__text {
      width: 45%;
    }
    &__date {
      width: 45%;
      &::before {
        left: 50%;
      }
    }
  }

  /*FAQ*/

  .faq {
    &__question {
      font-size: 1.7rem;
    }
    &__form {
      &-thanks {
        width: 80%;
      }
    }
  }

  /*Animate*/

  .banner {
    &__subtitle {
      font-size: 1.4rem;
    }
    &__animate {
      &-title {
        font-size: 1.4rem;
        em {
          font-size: 1rem;
        }
      }
      &-video {
        border-radius: 3rem;
        &:not(.mobile) {
          display: none;
          position: absolute;
          width: 0;
          height: 0;
          overflow: hidden;
        }
        &.mobile {
          display: block;
          position: static;
          width: 100%;
          height: initial;
          overflow: visible;
        }
      }
    }
  }

  /*Howtobuy*/

  .how {
    &__title {
      font-size: 3rem;
    }
    &__paragraph {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
    &__instruction {
      background-image: none;
    }
    &__price {
      flex-direction: column;
      >span {
        &:last-of-type {
          text-align: center;
        }
      }
    }
    &__contract {
      text-align: center;
    }
    &__banner {
      width: 80%;
      margin-right: auto;
    }
    &__crypto {
      position: fixed;
      top: 2vh;
      left: 3vw;
      width: 94vw;
      height: 96vh;
      overflow-y: scroll;
      background-color: #4c3892;
      padding-top: 5rem;
      padding-bottom: 6rem;
      &-list {
        display: flex;
        flex-direction: column;
        margin-top: 0;
        &::before {
          display: none;
        }
      }
      &-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 15%;
        padding-right: 15%;
        font-size: 1.2rem;
        svg {
          width: initial;
          margin-left: 0;
          margin-right: 1rem;
        }
        span {
          margin-left: .5rem;
        }
      }
      &-form {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        margin-top: 1rem;
        &.active {
          margin-bottom: 3rem;
        }
      }
      &-input {
        font-size: 1.2rem;
        max-width: 100%;
        padding: 1rem 15% 1rem 1.5rem;
        outline: 1px solid @light-color;
        border-radius: .5rem;
      }
      &-check {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 15%;
      }
      &-close {
        display: block;
      }
    }
    &__steps {
      flex-direction: column;
      align-items: center;
      &::before,
      &::after {
        display: none;
      }
      &-item {
        text-align: center;
        &:not(:last-of-type) {
          width: 80%;
          margin-bottom: 5rem;
          position: relative;
          &::after {
            content: '';
            width: 2.5rem;
            height: 2.5rem;
            background: url(../img/deco-arrow.png) no-repeat center/100% auto;
            position: absolute;
            right: 50%;
            top: initial;
            bottom: -4rem;
            transform: rotate(0) translateX(50%);
          }
        }
        &:last-of-type {
          margin-top: 1rem;
        }
      }
    }
  }
  .howtobuy_click_price>span {
    display: block;
  }

  /*Ukraine*/

  .uk {
    .banner {
      background-position: left 25vw top;
      &__text {
        font-size: 1.5rem;
      }
      &__about {
        width: 90%;
      }
    }
  }

  .popup {
    padding-left: 16px;
    padding-right: 16px;
  }

  .js-wallet {
    pointer-events: auto;
  }

}

@media (max-width: 750px) {
  html {
    font-size: 13px;
  }
  .subtitle {
    font-size: 3rem;
  }
  .button {
    font-size: 1.5rem;

    &_violet {
      margin-left: 0;
      margin-top: 2rem;
    }
  }

  /*Header*/

  .header {
    &__logo {
      font-size: 3.2rem;
    }
    &__menu {
      width: 65vw;
      padding-left: 11vw;
      &-item {
        font-size: 1.5rem;
      }
    }
  }

  /*Banner*/

  .banner {
    background-position: center;
    background-image: url("../img/banner-bg-mob.png");
    &:not(.uk .banner) {
      background-position: center;
    }
    &__about {
      width: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__title {
      margin-bottom: 3rem;
    }
    &__subtitle {
      font-size: 1.6rem;
    }
    &__text {
      width: 100%;
    }
    &__buttons {
      display: flex;
      flex-direction: column;
    }
    &__buttons {
      a {
        margin-left: 0;
        margin-right: 0;
        &:last-of-type {
          margin-left: 0;
          margin-top: 1rem;
        }
      }
    }
  }

  /*About*/
  .about {
    .chart {
      td {
        font-size: 1.8rem;
        span {
          &:first-of-type {
            display: none;
          }
          &:last-of-type {
            display: initial;
          }
        }
      }
    }
  }

  /*Aim*/

  .aim {
    &__title {
      text-align: center;
      br {
        display: none;
      }
    }
    &__text {
      text-align: center;
    }
    &__chart {
      width: 100%;
    }
  }

  /*Buy*/

  .buy {
    &__wrapper:not(.uk .buy__wrapper) {
      padding-left: 0;
      justify-content: center;
      background: none;
    }
    &__subtitle {
      position: relative;
      &::before,
      &::after {
        content: '';
        width: 5rem;
        height: 5rem;
        background: url("../img/coin.png") no-repeat center / contain;
      }
      &::before {
        margin-right: 1rem;
      }
      &::after {
        margin-left: 1rem;
      }
    }
    &__text {
      text-align: center;
    }
    &__button {
      margin-left: auto;
    }
  }

  /*Blockchain*/

  .blockchain {
    &__about {
      text-align: center;
    }
    &__subtitle {
      padding-left: 7.5rem;
      background: url("../img/coin.png") no-repeat left center / 5rem auto;
    }
  }

  /*Difference*/

  .difference {
    &__spoiler {
      width: 28rem;
    }
  }

  /*Partners*/

  .partners {
    padding-bottom: 0;
    margin-bottom: 8rem;
    border-bottom: 2px solid #564497;
    position: relative;
    &:not(.uk .partners) {
      &::before {
        content: '';
        width: 100%;
        height: 50%;
        background: linear-gradient(to top, rgba(0,0,0,.25), rgba(0,0,0,0));
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -1;
      }
    }
    &__item {
      flex-wrap: wrap;
      justify-content: center;
      &:not(:last-of-type) {
        margin-bottom: 3rem;
      }
    }
    &__text {
      width: 100%;
    }
    &__image {
      margin-top: 2rem;
      &:not(.mobile) {
        display: none;
      }
      &.mobile {
        display: block;
        width: 65%;
      }
    }
  }

  /*Timing*/

  .timing {
    &__container {
      &::after {
        left: 1rem;
        height: ~'calc(100% - 5rem)';
      }
    }
    &__paragraph {
      flex-wrap: wrap;
    }
    &__date {
      width: 100%;
      text-align: left;
      padding-left: 5rem;
      &::before {
        left: 1rem;
      }
    }
    &__text {
      width: 100%;
      padding-left: 5rem;
    }
    &__go {
      display: block;
      width: 70%;
      margin-left: auto;
      margin-right: auto;
      padding-left: 0;
      text-align: center;
      font-size: 2rem;
      margin-top: 3.5rem;
    }
  }

  /*Charity*/

  .charity {
    padding-top: 4rem;
    &__title {
      width: 100%;
      text-align: center;
    }
    &__paragraph {
      width: 100%!important;
      text-align: center!important;
      margin-left: auto;
      margin-right: auto;
      &:not(:last-of-type) {
        margin-bottom: 3rem;
      }
      &:nth-of-type(1) {
        &::before {
          display: block;
          position: static;
          margin-left: auto;
          margin-right: auto;
          height: 15rem;
          margin-bottom: 2rem;
        }
      }
      &:nth-of-type(2) {
        &::before {
          display: block;
          position: static;
          margin-left: auto;
          margin-right: 3rem;
          margin-bottom: 2rem;
          transform: none;
        }
        &::after {
          display: block;
          position: static;
          margin-right: auto;
          width: 10rem;
          height: 10rem;
          transform: none;
          background-image: url("../img/charity-bg2-mob.png");
        }
      }
      &:nth-of-type(3) {
        &::after {
          display: block;
          position: static;
          height: 17rem;
          margin-left: auto;
          margin-right: auto;
          margin-top: 2rem;
        }
      }
    }
    &__subtitle {
      padding-bottom: 16rem;
      width: 100%;
      font-size: 1.6rem;
      &::before {
        display: none;
      }
      &::after {
        left: 50%;
        transform: translateX(-50%);
        background-size: auto 13rem;
      }
    }
  }

  /*Howtobuy*/

  .how {
    &__title {
      font-size: 2.5rem;
    }

    &__title-container {
      flex-direction: column;
    }

    &__paragraph {
      width: 100%;
      text-align: center;
      margin-bottom: 3rem;
    }
    &__contract {
      text-align: center;
    }
    &__banner {
      width: 100%;
      margin-bottom: 8rem;
    }
    &__price {
      flex-wrap: wrap;
      justify-content: center;
      span {
        &:first-of-type {
          width: 100%;
          text-align: center;
        }
        &:last-of-type {
          width: 100%;
          text-align: center;
          font-size: 3rem;
        }
      }
    }
    &__telegram {
      width: 100%;
      text-align: center;
    }

    &__pay {
      flex-direction: column;
      width: 100%;
    }

    &__pay-button {
      width: 100%;
    }

    &__pay-wrapper {
      width: 100%;
      margin-bottom: 24px;
    }

    &__pay-error {
      width: 100%;
      word-break: break-word;
    }
  }

  /*FAQ*/

  .faq {
    &__answer {
      li {
        padding-left: 3rem;
        &::before {
          left: 0;
        }
        li {
          padding-left: 3rem;
          &::before {
            left: 0;
          }
        }
      }
    }
    &__form {
      &-item {
        width: 100% !important;
        input {
          padding-top: 1rem;
          padding-bottom: 1rem;
        }
      }
      &-thanks {
        width: 90%;
        font-size: 1.3rem;
      }
    }
  }

  /*Pause*/

  .pause {
    &__banner {
      p {
        padding-left: 0;
        padding-right: 0;
        font-size: 1.3rem;
      }
    }
    &__price {
      &-text {
        span {
          font-size: 1.3rem;
        }
        strong {
          display: none;
        }
      }
      &-number {
        font-size: 2.5rem;
      }
    }
  }

  /*Ukraine*/

  .uk {
    .banner {
      background-position: center;
      &__about {
        width: 100%;
      }
      &__flag {
        &:not(.mobile) {
          display: none;
        }
        &.mobile {
          display: block;
          position: static;
          transform: none;
          width: 100vw;
        }
      }
      &__text {
        margin-bottom: 0;
      }
    }

    .about {
      padding-top: 4rem;
      padding-bottom: 4rem;
      &__text, &__course, &__charge {
        text-align: center;
      }
      &__button {
        margin-left: auto;
      }
    }

    .buy {
      padding-top: 4rem;
      padding-bottom: 4rem;
      &__wrapper {
        padding-left: 0;
        padding-top: 7rem;
        background-size: auto 5rem;
        background-position: center top;
      }
      &__subtitle {
        text-align: center;
        &::before, &::after {
          display: none;
        }
      }
    }

    .difference {
      padding-top: 4rem;
      padding-bottom: 4rem;
      &__subtitle {
        padding-left: 0;
        padding-top: 7rem;
        background-size: auto 5rem;
        background-position: left top;
      }
      &__list {
        &-item {
          flex-wrap: wrap;
          justify-content: flex-start;
        }
        i {
          width: 100%;
        }
      }
      &__button {
        margin-top: 2rem;
        margin-left: 0;
      }
    }

    .fair {
      padding-top: 4rem;
      padding-bottom: 4rem;
      &__subtitle {
        padding-left: 0;
        padding-top: 7rem;
        background-size: auto 5rem;
        background-position: center top;
        text-align: center;
      }
      &__text {
        width: 100%;
        text-align: center;
      }
    }

    .partners {
      border-bottom: none;
      padding-top: 4rem;
      padding-bottom: 4rem;
      margin-bottom: 0;
      &__subtitle {
        padding-left: 0;
        padding-right: 7.5rem;
        background-position: right center;
        text-align: right;
        width: 100%;
        justify-content: flex-end;
      }
      &__text {
        width: 100%;
        text-align: center;
      }
      &__button {
        margin-left: auto;
      }
    }
  }

  .ukraine {
    &__wrapper {
      border-width: 3px;
    }
    &__tag {
      font-size: 2.8rem;
    }
  }

  .work {
    padding-bottom: 4rem;
    &__wrapper {
      &::before {
        position: static;
        margin-bottom: 3rem;
      }
    }
    &__about {
      width: 100%;
    }
    &__subtitle {
      width: 100%;
    }
    &__more {
      width: 90%;
    }
  }

  .who {
    &__text {
      width: 100%;
    }
  }

  .popup {
    padding-top: 48px;
    padding-bottom: 40px;

    &__title {
      font-size: 22px;
      line-height: 1.82;
      letter-spacing: 1.1px;
      text-shadow: 2px 2px 0 #000;
      margin-bottom: 40px;

      .popup__title_mark {
        font-size: 22px;
        line-height: 1.82;
        letter-spacing: 1.1px;
        text-shadow: 2px 2px 0 #000;
      }
    }

    &__btns-container {
      flex-direction: column;
    }

    &__btn {
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 0.8px;
      width: 240px;
      height: 56px;
      border-radius: 20px;
      border: solid 1px #000;
      margin: 0;

      &:last-child {
        margin-top: 16px;
      }
    }
  }

}

@media (max-width: 600px) {

  /* header */
  .header {
    &.active {
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;

      .header__logo {
        height: 3.5rem;
      }

      &.scrolled {
        background-color: transparent;
        border-bottom: none;
        padding: 3.5rem 0;

        .header__logo {
          height: 4.5rem;
        }
      }
    }
  }

  /*About*/

  .about {
    &__no {
      font-size: 2.7rem;
    }
  }
  .chart {
    th {
      font-size: 1.3rem;
    }
    td {
      border-width: 2px;
      font-size: 1.6rem;
      padding-left: .7rem;
      padding-right: .7rem;
    }
  }

  .vote__auth_loading {
    width: 140px;
    height: 140px;

    svg {
      width: 80px;
      height: 80px;
    }
  }
}

@media (max-width: 500px) {

  html {
    font-size: 11px;
  }
  .title {
    font-size: 2.2rem;
  }
  .subtitle {
    font-size: 2.8rem;
  }

  /*Header*/

  .btn-menu {
    height: 1.9rem;

    span {
      width: 3.5rem;
      height: 0.5rem;
    }

    &.active {
      span {
        &:first-of-type {
          top: 0.6rem;
        }

        &:last-of-type {
          bottom: 0.5rem;
        }
      }
    }
  }

  /*Banner*/

  .banner {
    &__title {
      font-size: 1.5rem;
    }
    &__subtitle {
      font-size: 1.4rem;
      width: 65%;
      span {
        font-size: 1.2rem;
      }
    }
  }

  /*About*/

  .chart {
    th {
      font-size: 1rem;
    }
  }

  /*Blockchain*/

  .blockchain {
    &__bridges {
      a {
        display: block;
        max-width: 15%;
      }
    }
  }

  /*Difference*/

  .difference {
    &__paragraph:not(.uk .difference__paragraph) {
      width: 100%;
      text-align: center;
    }
    &__banner {
      font-size: 1.3rem;
    }
    &__note {
      width: 100%;
      font-size: 1.5rem;
    }
    &__list {
      &-item {
        width: 100%;
      }
    }
  }

  /*Partners*/

  .partners {
    &__note {
      width: 100%;
      font-size: 1.6rem;
    }
    &__image {
      &.mobile {
        width: 80%;
      }
    }
  }

  /*Cookies*/

  .cookies {
    &__wrapper {
      flex-direction: column;
    }
    &__text {
      width: 100%;
      padding-right: 0;
      margin-bottom: .7rem;
    }
    &__ok {
      padding-top: .5rem;
      padding-bottom: .5rem;
    }
  }

  /*FAQ*/

  .faq {
    &__item {
      border-width: 3px;
    }
    &__question {
      font-size: 1.6rem;
    }
    &__answer {
      font-size: 1.2rem;
    }
  }

  /*Animate*/

  .banner {
    &__animate {
      &-title {
        font-size: 1.1rem;

        em {
          font-size: 1rem;
        }
      }
    }
  }

  /*Howtobuy*/

  .how {
    overflow: hidden;
    &__contract {
      flex-direction: column;
      align-items: center;
      &-copy {
        font-size: 1.5rem;
        color: @accent1-color;
        text-transform: uppercase;
        padding-top: 0;
        padding-left: 3rem;
        background-position: left center;
        background-size: 2.5rem;
        min-height: 2.5rem;
        margin-top: 1.5rem;
        span {
          &:nth-of-type(1) {
            display: none;
          }
          &:nth-of-type(2) {
            display: block;
          }
        }
      }
    }
    &__banner {
      width: 102vw;
      margin-left: -6vw;
      padding-left: 2.5rem;
    }
    &__steps {
      &-item {
        &:not(:last-of-type) {
          width: 95%;
        }
      }
    }
  }

  /*Charity*/

  .charity {
    &__title {
      em {
        font-size: 1.5rem;
      }
    }
  }

  /*Ukraine*/

  .uk {
    .subtitle {
      font-size: 2.4rem;
    }

    .banner {
      &__title {
        font-size: 2rem;
        strong {
          display: initial;
          font-size: 2rem;
        }
      }
      &__more {
        width: 100%;
      }
    }
  }

  .work {
    &__subtitle {
      font-size: 1.8rem;
    }
  }

}

@media (max-width: 380px) {

  html {
    font-size: 10px;
  }
}

@media (max-width: 350px) {
  .popup {
    &__title {
      font-size: 20px;

      .popup__title_mark {
        font-size: 20px;
      }
    }
  }
}

@media (max-width: 340px) {

  html {
    font-size: 9px;
  }

  .dapp-deeplink-button {
    max-width: 180px;
  }

}

@media (max-height: 740px) {
  .popup-container_wallet .popup {
    padding: 16px 24px 24px;
  }

  .popup-container_wallet .popup__title {
    margin-bottom: 10px;
  }

  .popup-container_wallet .popup__btn img {
    height: 64px;
    margin-bottom: 16px;
  }
}

@media (max-height: 620px) {
  .popup-container_wallet .popup {
    padding: 16px 16px 20px;
  }

  .popup-container_wallet .popup__title {
    font-size: 18px;
    line-height: 1.67;;
  }

  .popup-container_wallet .popup__btn {
    padding: 16px;
  }

  .popup-container_wallet .popup__btn img {
    height: 48px;
    margin-bottom: 8px;
  }
}

@media (max-height: 520px) {
  .popup-container_wallet .popup__title {
    font-size: 16px;
  }

  .popup-container_wallet .popup__btn h4 {
    font-size: 18px;
  }

  .popup-container_wallet .popup__btn {
    padding: 12px;
  }

  .popup-container_wallet .popup__btn p {
    font-size: 14px;
  }
}
